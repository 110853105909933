import React, {useEffect} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import CustomLink from "../../components/link";

const jQuery = require("jquery");

const NosotrosPage = (props) => {


    useEffect(() => {
        jQuery('.preloader').delay(200).fadeOut(500);
        const id = window.location.hash;
        if (id) {
            jQuery("html, body").animate({scrollTop: jQuery(id).offset().top - 80}, 500);
        }

        jQuery(".team-member-details").on('click', function (event) {
            var $this = jQuery(this);
            var item = $this.data('item');
            jQuery(".diretivo-perfil").addClass('d-none');
            jQuery('.diretivo-perfil.' + item).removeClass("d-none");
            jQuery("html, body").animate({scrollTop: jQuery('.diretivo-perfil.' + item).offset().top - 200}, 1000);
        });

        jQuery(".cerrar-info i").on('click', function (event) {
            jQuery(".diretivo-perfil").addClass('d-none');
        });
    });

    return (
        <Layout location={props.location}>
            <SEO title="Nosotros"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    A team of experts with extensive experience and track record</h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Who is LCA?</h2>
                            </div>

                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="acerca-de">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-5">
                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Latam Capital Advisors - LCA
                                </h6>
                                <ul className="p-0 list-style-02 margin-2-rem-top margin-3-rem-bottom">
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">We are a Latin America-focused financial services company with global outreach.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">Our executives have a unique approach built on decades of experience in the financial industry.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">We bring deep, specialized financial expertise, senior-level client engagement and innovative solutions.</span>
                                    </li>
                                    <li className="margin-15px-bottom"><i
                                        className="feather icon-feather-arrow-right-circle text-large text-fast-blue margin-10px-right"
                                        aria-hidden="true"></i><span className="text-extra-dark-gray alt-font">We work closely with our clients to provide top-notch services and consistently deliver results that exceed expectations.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/nosotros.jpg"} alt={''}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="trayectoria" className="bg-light-gray wow animate__fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="planning-tab" className="tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-6 text-right sm-margin-40px-bottom">
                                                <img src={"/images/lca/nosotros2.jpg"} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-5 offset-lg-1 col-md-6">
                                                <span className="alt-font d-block text-extra-medium margin-15px-bottom">An outstanding track record</span>
                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Our Trajectory</h5>
                                                <p>Our company was established in 2009 as a subsidiary of MBIA Inc
                                                    (NYSE: MBI) and has been operating independently since 2012.</p>
                                                <p> Since its inception, Latam Capital Advisors has been directly
                                                    involved as financial advisor and structuring agent in more than 25
                                                    financings, mergers, and acquisitions for over <strong>US$14.5
                                                        billion</strong> in
                                                    total, in addition to more than US$3 billion in the pipeline.</p>
                                                <p>Since 2020, we act as fund manager and general partner for the
                                                    private equity fund Latam Capital Fondo I, dedicated to
                                                    promoting investments in the sectors in which we specialize in the
                                                    region.
                                                </p>
                                                <CustomLink to="nosotros#transacciones"
                                                            className="btn btn-medium btn-dark-gray margin-15px-top">TAKE
                                                    A LOOK AT OUR TRACK RECORD</CustomLink>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end tab item */}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='pb-0' id="equipo">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-7 col-sm-8 text-center margin-3-rem-bottom wow animate__fadeIn">

                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">Management team</h5>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-5 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure className={'team-member-details'} data-item="1">
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/lca/equipo/eugenio.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Mr. Eugenio Mendoza</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">LCA Partner</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure className={'team-member-details'} data-item="2">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/kenett.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Mr. Kenneth Kryzda</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">LCA Partner</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure className={'team-member-details'} data-item="3">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/gonzalo.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Mr. Gonzalo Obregón</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">LCA Partner</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure className={'team-member-details'} data-item="4">
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/lca/equipo/francisco.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Francisco Cuauhtémoc Morales</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">LCA Partner</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure className={'team-member-details'} data-item="5">
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/lca/equipo/federico.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">
                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column padding-20px-lr padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Federico Patiño</span>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                {/*detalles*/}
                <section className="d-none diretivo-perfil 1 wow animate__fadeIn pb-1">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Mr. Eugenio Mendoza</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        LCA Partner
                                    </em>
                                </div>
                                <p>Mr. Mendoza has more than 30 years of experience in sub-sovereign infrastructure
                                    project finance, corporate clients, and structured finance. He began his career at
                                    Citibank working on export financings.</p>
                                <p>For 18 years, he worked for Merrill Lynch as an investment banker in Puerto Rico, New
                                    York, and London. He established and led the global infrastructure finance unit
                                    headquartered in New York and London.
                                </p>
                                <p>Prior to joining Latam Capital Advisors, he was responsible for the development of
                                    MBIA’s financial guarantees business in Latin America and Canada, including the
                                    arrangement of the first 30-year financing in the Mexican capital markets, several
                                    infrastructure asset financings in Chile, and the use of structured instruments to
                                    mitigate currency and interest rate risks.
                                </p>
                                <p>He has participated in the purchase/sale of highway concessions in Canada, China,
                                    Argentina, Brazil and Mexico, the purchase and/or financing of airports in
                                    Argentina, Australia and Puerto Rico, the purchase/sale of energy assets in Western
                                    Europe, and the management of infrastructure assets in several Latin American
                                    countries.</p>
                                <p>Boston College (MBA)
                                </p>
                                <p>The Ibero-American University (B.S. in Accounting)</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 2 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Mr. Kenneth Kryzda</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        LCA Partner
                                    </em>
                                </div>
                                <p>Mr. Kryzda has over 35 years of experience in global banking, with particular
                                    expertise in structuring infrastructure projects and mergers & acquisitions in Latin
                                    America.
                                </p>
                                <p>Before joining LCA, he was CEO and Managing Director of HSBC’s Global Banking
                                    division where he put together a successful client-focused global team and turned
                                    this unit into HSBC’s most profitable business line for 3 years in a row. Mr. Kryzda
                                    also took the Infrastructure Project Finance team to a leadership position in
                                    Mexico.</p>
                                <p>He served as senior officer at Bank of America in Mexico City, where he acted as
                                    deputy co-CEO of the investment banking division. He led the Media and
                                    Telecommunications, Consumer Brands, and Commodities teams.
                                </p>
                                <p>Prior to joining Bank of America, he was an executive officer at JPMorgan Chase where
                                    he led the investment banking coverage groups for Consumer Goods, Commodities,
                                    Transportation and Mexican Conglomerates.</p>
                                <p>He began his career at Chase Manhattan Bank in New York.
                                </p>
                                <p>Southern Methodist University (B.S. in Economics and Finance).
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 3 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Mr. Gonzalo Obregón</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        LCA Partner
                                    </em>
                                </div>
                                <p>Mr. Obregón has over 20 years of experience in finance, including underwriting
                                    financings for infrastructure projects in Latin America. Currently settled in New
                                    York.</p>
                                <p>Before joining LCA, he spent more than 9 years working with MBIA's new business
                                    development group where he structured and arranged over 10 financing transactions
                                    for clients in Mexico and Chile worth roughly $4.5 billion dollars and was also
                                    tasked with opening deals and handling client relationships in the region.</p>
                                <p>Prior to MBIA, he served 4 years as a consultant at Cohen & Asociados, a Chilean firm
                                    specialized in financial advisory in the region, where he put together teams and led
                                    financial consulting projects for industrial and financial companies.</p>
                                <p>He began his career at Banco de Chile, the country’s largest commercial bank,
                                    developing simulation models for the bank’s consumer loans portfolio.</p>
                                <p>UC Berkeley (M.S. in Financial Engineering).
                                </p>
                                <p>University of Chile (Civil Industrial Engineering).</p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 4 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Francisco Cuauhtémoc Morales</h3>
                                <div className="persona-cargo ">
                                    <em>
                                        LCA Partner
                                    </em>
                                </div>
                                <p>Mr. Morales has more than 15 years of experience in financial and economic analysis
                                    and in the development of complex financial models.</p>
                                <p>Prior to joining LCA, he worked for 5 years in MBIA's new business development team,
                                    which he joined in 2007, where he structured and arranged over $800 million dollars
                                    in financing for infrastructure projects and sub-sovereign entities.</p>
                                <p>He has carried out valuations for multiple infrastructure assets and corporations
                                    operating in Latin America.
                                </p>
                                <p>He began his career as an Economist in the Economic Research Department of Banco de
                                    México, carrying out research projects on various topics and developing econometric
                                    and statistical models.</p>
                                <p>Chartered Financial Analyst (CFA).
                                </p>
                                <p>Yale University - School of Management (MBA).</p>
                                <p>Monterrey Institute of Technology and Higher Education (B.A. in Economics).
                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="d-none diretivo-perfil 5 wow animate__fadeIn">
                    <div className='container'>
                        <div className="row  align-items-center">
                            <div className="col-md-12">
                                <div className="cerrar-info">
                                    <i className="fa fa-times fa-2x "></i>
                                </div>
                                <h3>Federico Patiño</h3>
                                <div className="persona-cargo ">

                                </div>
                                <p>Mr. Patiño has more than 30 years in the financial sector.</p>
                                <p>From 2015 to 2018, he was CEO of Grupo Aeroportuario de la Ciudad de México, S.A de
                                    C.V. (GACM) and previously served as its CFO since 2014. During this period, he was
                                    responsible for the financing of the new airport project and the second terminal of
                                    Mexico City's international airport.
                                </p>
                                <p>Prior to his position at GAMC, he was in charge of the founding and general
                                    management of the National Infrastructure Fund (FONADIN) at Banco Nacional de Obras
                                    y Servicios Públicos, S.N.C. (BANOBRAS).</p>
                                <p>From 1980 to 2008, he worked at Nacional Financiera, S.N.C., Institución de Banca de
                                    Desarrollo (NAFIN), where he held several positions including, among others, General
                                    Director of Credit, General Director of Treasury, General Director of Development,
                                    and General Director of Investment Banking.</p>
                                <p>He was responsible for the creation of the Mexican Equity Investment Corporation
                                    (CMIC), a fund of private equity funds created in 2006 by Mexican development banks.
                                    CMIC invests in private equity funds that finance Mexican companies.</p>

                            </div>
                        </div>
                    </div>
                </section>
                {/*end detalles*/}

                <section className='pb-0' id="reconocimientos">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-12  text-center margin-3-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                                <h5 className="mb-1 alt-font font-weight-500 text-extra-dark-gray">Awards</h5>
                                <span
                                    className="d-inline-block alt-font text-extra-dark-gray text-large text-uppercase font-weight-500 letter-spacing-1px margin-20px-bottom margin-10px-top">+20 INTERNATIONAL AWARDS AND RECOGNITIONS IN OUR TRAJECTORY</span>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p1_y_p3.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best <br></br>Financing Innovation</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p2.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America <br></br>Domestic Currency Bond</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p1_y_p3.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best <br></br>Structured Financing Deal</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">IFREM MXN $4,100m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p4.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Latin America Project <br></br>Bond Deal of the Year</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social MXN $8,800m</span>

                                    </figcaption>
                                </figure>
                            </div>


                        </div>
                    </div>

                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p5.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America<br></br> Domestic Currency Bond</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social MXN $8,800m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p6.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best <br></br>Financing Innovation</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA Infraestructura Social
MXN $8,800m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/awards2014.jpg"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">IJ Global Americas <br/> Awards 2014</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">ICA: LIPSA | ICASAL MXN $3,800m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p7.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Airport Finance<br></br> Deal of the Year - Americas</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p8.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America <br></br>Airport
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p9.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Latin America<br></br> Deal of the Year</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p10.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Airport Finance<br></br> Innovative Deal of the Year </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p11.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Latin America<br></br> Corporate Bond </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $2,000m Green Bond</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p12_y_p13.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Structured Financing<br></br> of the Year
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $2,000m Green Bond
</span>
                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 16" src="/images/p14_16.png"/>
                                        <div className="team-overlay bg-transparent-gradient-tussock-greenish-slate">

                                        </div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best Loan</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p12_y_p13.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Syndicated Loan <br></br>of the Year

</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p14_16.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Best Infrastructure<br></br> Financing Mexico
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p14_16.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best<br></br> Airport Financing
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.4s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 17" src="/images/p17.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span
                                            className="team-title d-block alt-font text-white">Green Bond Pioneer:<br></br> Largest Corporate
Green Bond


</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $2,000m Green Bond


</span>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center wow animate__fadeIn" data-wow-delay="0.8s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 19" src="/images/p18.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best Loan



</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $3,000m
</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p19_p20.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best <br></br>Airport Financing
</span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $4,000m Green Bond

</span>

                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col team-style-01 text-center xs-margin-15px-bottom wow animate__fadeIn"
                                 data-wow-delay="0.6s">
                                <figure>
                                    <div className="team-member-image">
                                        <img alt="About team 18" src="/images/p19_p20.png"/>
                                        <div
                                            className="team-overlay bg-transparent-gradient-tussock-greenish-slate"></div>
                                    </div>
                                    <figcaption
                                        className="align-items-center justify-content-center d-flex flex-column  padding-30px-tb">
                                        <span className="team-title d-block alt-font text-white">Best Local<br></br>
                                        Currency Financing

                                        </span>
                                        <span
                                            className="team-sub-title text-small d-block text-white-transparent text-uppercase">GACM: NAICM
USD $1,600m Fibra E
</span>

                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='pb-0' id="transacciones">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-12 col-lg-12  text-center margin-3-rem-bottom md-margin-3-rem-bottom wow animate__fadeIn">
                                <h5 className="mb-1 alt-font font-weight-500 text-extra-dark-gray">Transaction track
                                    record</h5>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid padding-eight-lr lg-padding-15px-lr">
                        <div
                            className="row row-cols-1 row-cols-lg-4 row-cols-sm-2 justify-content-center padding-3-rem-bottom">
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,750 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/1.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent of senior bank financing</p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2020</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/2.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of
                                            long-term senior notes with ticker symbol CAMSCB 19U
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2019</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $30,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/3.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor for the largest issuance of energy and infrastructure
                                            investment trust certificates in Mexico (FIBRA E)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/4.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory for the structuring, arrangement, and formalization of an
                                            inflation-linked loan for a bank loan refinancing
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $2,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/5.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory for bidding and arranging financing for a maintenance,
                                            rehabilitation, and operation contract
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP 1,581 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/28.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p> Financial advice for the tender and contracting of financing for the maintenance, rehabilitation and operation contract under the PPP scheme
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2018</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $4,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/6.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor for the issuance of 10- and 30-year 144 A
                                            / Reg S bond issuances in the United States, Europe, and Asia
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2017</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $2,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/7.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor for the issuance of 10- and 30-year 144 A
                                            / Reg S bond issuances in the United States, Europe, and Asia
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2016</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $3,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/8.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor for the issuance of 10- and 30-year 144 A
                                            / Reg S bond issuances in the United States, Europe, and Asia
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $5,225 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/9.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory and negotiation for restructuring a senior bank loan
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,470 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/10.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term
                                            subordinated notes
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2015</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>USD $1,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/11.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory for the structuring and arrangement of a revolving credit
                                            facility (club deal)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP ~$3,014 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/12.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory for the partial sale (M&A) of 4 highway assets in Mexico
                                            and the creation of the roadway holding entity OVT
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,600 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/13.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term local
                                            notes (PAMMSCB 14U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $3,800 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/14.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of domestic senior
                                            notes (LPSLCB 14U and 14-2U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,750 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/15.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of local
                                            subordinated notes (LIPSBCB 12U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2014</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $2,500 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/16.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term
                                            domestic subordinated notes (AMCCB 13U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidential</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/21.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory and structuring of a short-term bridge loan facility
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $4,200 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/22.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term
                                            domestic senior notes
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $825 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/23.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term local
                                            subordinated notes
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2013</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,000 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/17.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory and arrangement of a structured bank loan for social infrastructure
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2012</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $1,700 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/18.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance domestic
                                            subordinated notes (PSBCB 12U)
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2012</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP $7,100 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/19.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the issuance of long-term
                                            domestic senior notes at construction stage
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidential</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/20.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory and structuring agent for the issuance of long-term
                                            subordinated financing
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>

                          

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidential</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/24.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory and structuring of PPP project and payment source
                                            enhancement for the bidding the construction and management a
                                            government headquarters
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2011</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>MXP ~$4,300 M</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/25.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisor and structuring agent for the
                                            issuance of long-term domestic secured notes
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2010</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Consulting</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/26.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Consultancy to the Government of the State of Nuevo León and REA on the
                                            valuation of the Monterrey Ring Road
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2009</b></p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col team-style-01 text-center md-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                                data-wow-delay="0.2s">
                                <div className="p-2">
                                    <div>
                                        <p className="text-black mb-0"><b>Confidential</b></p>
                                    </div>
                                    <hr width="85%" className="mt-1 bg-black"/>
                                    <img className="mb-3" src="/images/lca/trayectoria/27.jpg" alt=""/>
                                    <div className="pb-4">
                                        <p>Financial advisory for the structuring of subordinated financing for the
                                            Mexico-Toluca highway
                                        </p>
                                    </div>
                                    <div className="position-absolute">
                                        <p className="text-black mb-0"><b>2009</b></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
